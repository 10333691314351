#dashboard {
    min-height: 100vh;
}


.active {
    font-weight: bolder;
    border-left: 2px solid black;
}

.conts {
    background: #1f1f1f;
    color: #d1d1d1;
}

#side-bar hr {
    border-color: ghostwhite;
}



#side-bar {
    height: calc(100vh - 1rem);
    position: sticky;
    top: 0.5rem;
    transition: all 0.3s ease-in;
}
#side-bar span {
    padding: 0 1.5rem;
}
.short-side {
    width: auto!important;
}
.short-side span {
display: none;
}
#side-bar li > a, #side-bar > i, #side-bar div {
    padding: 0 2rem;
}
#side-bar li > a {
    display: flex;
    align-items: center;
    width: 100%;
}
#side-bar ul > li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
#side-bar ul > li:hover {
    background: #e5e7eb;
}
#side-bar ul > li i {
    padding: 1.5rem 0;
    width: 18px;
    text-align: center;
}

@media screen and (max-width: 720px) {
    .short-side {
        position: fixed;
        width: calc(100vw - 1rem);
        height: calc(100vh - 1rem);
    }

    #side-bar {
        z-index: 10;
        position: fixed;
        color: ghostwhite;
        background: #1f1f1f;
        width: calc(100vw - 1rem);
        height: calc(100vh - 1rem);
        margin: 0.5rem;
        top: 0;
    }
}

#systems span {
    min-width: 64px;
}

.dark canvas {
    background-image:
            linear-gradient(to right, #21262d 1px, transparent 1px),
            linear-gradient(to bottom, #21262d 1px, transparent 1px);
    border: 2px solid #21262d;
    background-size: 25px 25px;
}

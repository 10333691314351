@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

.dark #root {
    background: #0d1117;
}

.morning {
    background: url("img/cloud-back.jpg") center;
    background-size: cover;
}
.night {
    background: rgb(15 23 42);
    background: url("img/night-back.jpg");
}

body {
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
/*    background: url("img/possibility.webp");*/
/*    color: white;*/
}
/*.sec-text {*/
/*    color: ghostwhite;*/
/*}*/

/*#hero-form {*/
/*    backdrop-filter: blur(10px);*/
/*    border: 1px solid transparent;*/
/*    background: rgba(255, 255, 255, 0.1);*/
/*}*/

/*#hero-form input {*/
/*    background: ghostwhite;*/
/*}*/

progress::-webkit-progress-bar {
    background-color: #21262d;
}

html {
    /*noinspection CssInvalidPropertyValue*/
    overflow-y: overlay;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: black;
}

#root, #web {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    font-family: Poppins, sans-serif;
}

@media screen and (min-width: 1024px) {
    #web {
        /*
            Image by liuzishan on Freepik
            https://www.freepik.com/free-vector/3d-earth-graphic-symbolizing-global-trade-illustration_14803715.htm#query=server%20background&position=33&from_view=keyword
        */
        background-image: url("/src/img/bgImage.webp");
        background-size: cover;
        background-position: bottom;
    }
}



.form-element {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}
.form-element label {
    font-size: 16px;
    font-weight: bold;
    opacity: 0.6;
    margin-bottom: 4px;
}
.form-element input {
    outline: none;
    padding: 10px 16px;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
}
.form-element input::placeholder {
    font-size: 14px;
}

.fa-google {
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
}

button:disabled {
    opacity: 0.6;
}


#noti-stack {
    margin: 2.5rem;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 50;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.notification {
    padding: 16px 24px;
    border-radius: 8px;
    font-size: 14px;
    width: fit-content;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(30, 144, 255, 0.9);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 0.25);
    margin-top: 20px;
}

.ani-nots {
    opacity: 0;
    animation: nots 5s;
}
@keyframes nots {
    0% {
        margin-bottom: -20px;
        opacity: 0;
    }
    10% {
        margin-bottom: 0;
        opacity: 1;
    }
    90% {
        margin-bottom: 0;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.success {
    background-color: mediumseagreen;
}

.info {
    background-color: lightcoral;
}
.failed {
    background-color: lightcoral;
}


#hero-section h1 {
    line-height: 1.2;
}

#heroImage {
    height: 100%;
}

.nav-links li {
    margin: 0 16px;
    transition: all ease-in;
}


.nav-links > li > a {
    opacity: 0.9;
}
.nav-links > li > a:hover {
    opacity: 1;
    font-weight: bolder;
}
@media screen and (max-width: 1024px) {
    input::placeholder {
        font-size: 14px;
    }

    #side-menu {
        position: fixed;
        left: 0;
        top: 92px;
        width: 100vw;
        z-index: 10;
        align-items: center;
        border-radius: 0 0 32px 32px;
        flex-direction: column;
    }
    #side-menu::after {
        content: " ";
        width: 25vw;
        max-width: 200px;
        height: 6px;
        border-radius: 8px 8px 0 0;
        background-color: #9ca3af;
        display: block;
        margin: 20px auto 0;
    }
    #side-menu ul {
        width: 100%;
        align-items: start;
        flex-direction: column;
        margin: 0 0 40px;
    }
    #side-menu ul > li {
        padding: 12px 50vw 12px 12px;
        margin-bottom: 10px;
        font-weight: bolder;
        border-bottom: 1px solid slategray;
    }
}


.dark {
    background-color: #0d1117;
    color: white;
}
.dark #navbar {
    background: linear-gradient(180deg, rgba(0,0,0,0.8), transparent)
}
.primary-btn {
    background: mediumseagreen;
}
.dark #footer {
    color: #9ca3af;
}
.dark .fab {
    color: slategray;
}
.dark button {
    color: whitesmoke;
}
.dark button:hover {
    background-color: black;
}
.dark .sec-btn {
    background-color: #0d1117;
    color: ghostwhite;
}
.dark .danger-btn {
    padding: 12px 16px;
    border: 2px solid #da3633;
    background: transparent;
}
.dark .sec-text {
    color: #9ca3af;
}
.dark #side-menu {
    background-color: #161b22;
}
.dark input, .dark select {
    color: white;
    background-color: #1f1f1f;
    border: 0;
}
.dark select {
    border-right: 0.5rem solid #1f1f1f;
    color: #c9d1d9;
}
.dark hr, .dark span {
    border-color: slategray;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #1f1f1f inset !important;
    -webkit-text-fill-color: white !important;
}

.dark div, .dark ul, .dark table {
    border-color: #21262d;
}
thead {
    opacity: 0.6;
}
tr {
    border-bottom: 1px solid #c9d1d9;
}
.dark tr {
    border-bottom: 1px solid #21262d;
}
td, th {
    text-align: center;
    padding: 1.5rem;
}

.dark td, .dark th {
    color: #c9d1d9;
}
.dark #dashboard span, .dark #dashboard i, .dark #dashboard a {
    color: #c9d1d9;
}
.dark li.active {
    border-color: ghostwhite;
}
.dark #side-bar li:hover {
    background-color: #1f1f1f;
}
.dark .text-highlight {
    color: aquamarine!important;
}
.dark #search-bar input {
    background-color: transparent;
}
.dark .text-green-600 {
    color: rgba(22, 163, 74)!important;
}
.dark .text-red-600 {
    color: rgba(220, 38, 38)!important;
}
.dark .text-yellow-600 {
    color: rgba(202, 138, 4)!important;
}

.dark #systems i {
    color: dimgray;
}

@media screen and (min-width: 1024px) {
    .dark #navbar {
        background-color: transparent;
    }
    .dark #side-menu {
        background-color: transparent;
    }
    .tabs i {
        margin-right: 0.5rem;
    }
}
.tabs li {
    padding: 1rem;
    cursor: pointer;
}
.dark .tabs li, .dark .tabs li > i {
    color: #9ca3af;
}
.tabs li {
    color: #21262d;
}
.dark .active-tab {
    color: ghostwhite!important;
    border-bottom: 2px solid ghostwhite;
}
